import React, { useState } from 'react';
import { Button, Form, Input, Typography } from 'antd';
import styled from '@emotion/styled';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../redux/hooks';
import { setUser } from '../../redux/authSlice';
import useFetch from '../../utils/useFetch';
import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';
import Header from '../Header';

const FormContainer = styled.div(() => ({
  margin: 'auto',
}));

const Container = styled.div(() => ({
  maxWidth: 300,
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
  padding: 30,
  borderRadius: 8,
  backgroundColor: '#F8F8FF',
}));

const Login = () => {
  const { Title } = Typography;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [loginError, setLoginError] = useState<string | null>(null);
  const [cookies, setCookie] = useCookies(['token']);

  const token = cookies.token;

  const { execute } = useFetch(`${process.env.REACT_APP_BASE_URL}auth/login`, 'POST', false);

  const onFinish = async (values: any) => {
    const responseData = await execute(JSON.stringify(values));

    if (responseData.detail !== undefined) {
      setLoginError(responseData.detail);
    } else {
      dispatch(setUser(responseData));
      setCookie('token', responseData.token, { path: '/' });
      setLoginError(null);
      navigate('/welcome');
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  if (token) {
    return <Navigate to="/welcome" replace />;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
      <Header />
      <FormContainer>
        <Title level={5}>Login</Title>
        <div style={{ color: 'red', marginBottom: '0.5rem' }}>{loginError}</div>
        <Container>
          <Form
            name="basic"
            layout="vertical"
            labelCol={{ span: 8 }}
            style={{ maxWidth: 600 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  type: 'email',
                  message: 'Please input your email!',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit">
                Login
              </Button>
            </Form.Item>
            {"Don't have an account? "}
            <Link to={'/register'}>
              <b>Register</b>
            </Link>
          </Form>
        </Container>
      </FormContainer>
    </div>
  );
};

export default Login;
