import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import LoadingIcon from '../../components/icons/Loading';
import ScaningIcon from '../../components/icons/Scaning';
import CloneIcon from '../../components/icons/Clone';
import AlmostThereIcon from '../../components/icons/AlmostThere';
import Layout from '../../layout';

// const icons = [
//   { icon: LoadingIcon, text: "Loading..." },
//   { icon: ScaningIcon, text: "Cloning Repository..." },
//   { icon: CloneIcon, text: "Scaning..." },
//   { icon: AlmostThereIcon, text: "Almost There..." },
// ];

const Text = styled.div(() => ({
  color: 'black',
  fontWeight: 'bold',
}));

const Container = styled.div(() => ({
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: 30,
}));

const SpinerContent = styled.div(() => ({
  display: 'inline-flex',
  alignItems: 'center',
  flexDirection: 'column',
  padding: 20,
  gap: 10,
}));

function Loading() {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const [changeCount, setChangeCount] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    const changeText = () => {
      setChangeCount(changeCount + 1);
    };

    const textChangeInterval = setInterval(changeText, 15000);

    return () => {
      clearTimeout(textChangeInterval);
    };
  }, [changeCount]);

  if (changeCount >= 4) {
    navigate('/repository');
    return null;
  }

  return (
    <Layout>
      <Container>
        <div>
          <SpinerContent>
            <LoadingIcon color="black" size={40} />
            <Text>Migrating</Text>
          </SpinerContent>
        </div>
        <Spin indicator={antIcon} />
      </Container>
    </Layout>
  );
}

export default Loading;
