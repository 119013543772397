import { Spin } from 'antd';
import React, { FC } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';

const Container = styled.div(() => ({
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
}));

interface ProgressIndicatorProps {
  isLoading: boolean;
}
const ProgressIndicator: FC<ProgressIndicatorProps> = ({ isLoading }) => {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <>
      {isLoading && (
        <Container>
          <Spin indicator={antIcon} />
        </Container>
      )}
    </>
  );
};

export default ProgressIndicator;
