import { configureStore } from '@reduxjs/toolkit';
import { taskApi } from './api/repository';
import { taskListApi } from './api/file';
import { documentApi } from './api/document';
import { environmentApi } from './api/environment';
import { repoTableApi } from './api/repo_table';
import authSlice from './authSlice';
import { userApi } from './api/user';
import { contactApi } from './api/contact';
import { setupListeners } from '@reduxjs/toolkit/query';

export const store = configureStore({
  reducer: {
    auth: authSlice,
    [userApi.reducerPath]: userApi.reducer,
    [taskApi.reducerPath]: taskApi.reducer,
    [taskListApi.reducerPath]: taskListApi.reducer,
    [documentApi.reducerPath]: documentApi.reducer,
    [environmentApi.reducerPath]: environmentApi.reducer,
    [repoTableApi.reducerPath]: repoTableApi.reducer,
    [contactApi.reducerPath]: contactApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(userApi.middleware)
      .concat(taskApi.middleware)
      .concat(taskListApi.middleware)
      .concat(documentApi.middleware)
      .concat(environmentApi.middleware)
      .concat(repoTableApi.middleware)
      .concat(contactApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

setupListeners(store.dispatch);
