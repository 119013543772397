import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { DocumentId, DocumentsResponse } from '../../types/document';

export const documentApi = createApi({
  reducerPath: 'documentApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers: Headers, { getState }: any) => {
      const state = getState();
      if (state) {
        const token = state.auth.user.token;
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['document'],
  endpoints: (builder) => ({
    getUploadDocument: builder.mutation<DocumentsResponse, DocumentId>({
      query: (id) => ({
        url: `/document/${id}`,
      }),
      invalidatesTags: ['document'],
    }),
    uploadDocument: builder.mutation<DocumentsResponse, { formData: FormData; repository_id: string }>({
      query: ({ formData, repository_id }) => ({
        url: `/document/${repository_id}`,
        method: 'POST',
        body: formData,
        formData: true,
      }),
      invalidatesTags: ['document'],
    }),
    deleteDocument: builder.mutation<DocumentsResponse, number | undefined | string>({
      query: (repositoryId) => ({
        url: `/document/${repositoryId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['document'],
    }),
  }),
});

export const { useGetUploadDocumentMutation, useUploadDocumentMutation, useDeleteDocumentMutation } = documentApi;
