import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
export const repoTableApi = createApi({
  reducerPath: 'repoTableApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers: Headers, { getState }: any) => {
      const state = getState();
      if (state) {
        const token = state.auth.user.token;
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['repo_tables'],
  endpoints: (builder) => ({
    repoTable: builder.query({
      query: (id) => ({
        url: `/repo_tables/${id}`,
      }),
      providesTags: ['repo_tables'],
    }),
  }),
});

export const { useRepoTableQuery } = repoTableApi;
