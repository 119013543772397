import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { CreateRepository, Repository, UpdateRepository, AddSchema } from '../../types/repository';
import { useEffect } from 'react';

interface SummaryResponse {
  branch_name: string;
  git_url: string;
  pr_url: string;
  database_name: string;
  language: string;
  version: string;
  num_files_to_analyze: number;
  dependency_management: string;
  test_env_vars: string;
  test_directory: string;
  execution_status: number;
  error: string;
  total_lines_before: string;
  total_lines_after: string;
  total_files_before: string;
  total_files_after: string;
  total_functions_before: string;
  total_functions_after: string;
  total_classes_before: string;
  total_classes_after: string;
  test_count_before: string;
  test_count_after_generation: string;
  test_count_after_migration: string;
  test_percent_before: string;
  test_percent_after_generation: string;
  test_percent_after_migration: string;
  coverage_before: string;
  coverage_after_generation: string;
  coverage_after_migration: string;
  complexity_before: string;
  complexity_after_generation: string;
  complexity_after_migration: string;
  num_files_before: string;
  num_files_after: string;
  num_lines_before: string;
  num_lines_after: string;
}

interface LibrariesResponse {
  name: string;
  version: string;
}

export const taskApi = createApi({
  reducerPath: 'taskApi',
  // baseQuery: fetchBaseQuery({
  //   baseUrl: process.env.REACT_APP_BASE_URL,
  // }),
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers: Headers, { getState }: any) => {
      const state = getState();
      if (state) {
        const token = state.auth.user.token;
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['repository'],
  endpoints: (builder) => ({
    getRepository: builder.query<Repository[], void>({
      query: () => ({
        url: `/repository/user`,
      }),
      providesTags: ['repository'],
    }),
    getRepositoryUserById: builder.query<Repository[], void>({
      query: () => ({
        url: `/repository/user`,
      }),
      providesTags: ['repository'],
    }),
    getRepositoryById: builder.query<Repository, string>({
      query: (id) => `/repository/${id}`,
      providesTags: ['repository'],
    }),
    createRepository: builder.mutation<{ id: string }, CreateRepository>({
      query: (input) => ({
        url: `/repository`,
        method: 'POST',
        body: input,
      }),
      invalidatesTags: ['repository'],
    }),
    updateRepository: builder.mutation<void, UpdateRepository & { id: number }>({
      query: (input) => ({
        url: `/repository/${input.id}`,
        method: 'PUT',
        body: input,
      }),
      invalidatesTags: ['repository'],
    }),
    getAllTables: builder.query({
      query: (id) => `/repository/${id}/table`,
      providesTags: ['repository'],
    }),
    getRepoSummary: builder.query<SummaryResponse, string | undefined>({
      query: (id) => `/repository/${id}/repo_summary`,
      providesTags: ['repository'],
    }),
    getLibraries: builder.query<LibrariesResponse[], string | undefined>({
      query: (id) => `/repository/${id}/libraries`,
      providesTags: ['repository'],
    }),
    createFile: builder.mutation<{ id: string }, string>({
      query: (id) => ({
        url: `/repository/${id}/execute`,
        method: 'POST',
        body: { id },
      }),
      invalidatesTags: ['repository'],
    }),
    startExecution: builder.mutation<{ id: string }, string>({
      query: (id) => ({
        url: `/repository/${id}/execute`,
        method: 'POST',
        body: { id },
      }),
      invalidatesTags: ['repository'],
    }),
    deleteRepo: builder.mutation({
      query: (id) => ({
        url: `/repository/${id}`,
        method: 'DELETE',
        credentials: 'include',
      }),
      invalidatesTags: ['repository'],
    }),
    addSchema: builder.mutation<{ repository_id: number; schema_contents: string }, AddSchema>({
      query: (input) => ({
        url: `/repository/add-schema`,
        method: 'POST',
        body: input,
      }),
      invalidatesTags: ['repository'],
    }),
  }),
});

export const {
  useGetRepositoryQuery,
  useGetRepositoryByIdQuery,
  useGetRepositoryUserByIdQuery,
  useCreateRepositoryMutation,
  useGetAllTablesQuery,
  useCreateFileMutation,
  useGetRepoSummaryQuery,
  useGetLibrariesQuery,
  useUpdateRepositoryMutation,
  useDeleteRepoMutation,
  useAddSchemaMutation,
  useStartExecutionMutation,
} = taskApi;
