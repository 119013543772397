import styled from '@emotion/styled';
import React, { FC } from 'react';

const RootContainer = styled.button<{
  width: string | number;
  height: string | number;
  hoverColor: string | undefined;
}>(({ width, height, hoverColor }) => ({
  width: width,
  height: height,
  borderRadius: 30,
  backgroundColor: hoverColor ? 'white' : '#FF4800',
  color: hoverColor ? '#FF4800' : 'white',
  ':hover': {
    backgroundColor: hoverColor ? '#FF4800' : '#4AC728',
    transitionDuration: '0.5s',
    color: '#fff',
  },
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontFamily: 'Dosis,sans-serif',
  cursor: 'pointer',
}));

interface AButtonProps {
  width: string | number;
  height: string | number;
  name: string;
  hoverColor?: string | undefined;
  onClick?: () => void;
  loading?: boolean | undefined;
}

const AButton: FC<AButtonProps> = ({ width, height, name, hoverColor, onClick, loading = false }) => {
  return (
    <RootContainer width={width} height={height} hoverColor={hoverColor} onClick={onClick} disabled={loading}>
      {loading ? '...loading' : name}
    </RootContainer>
  );
};
export default AButton;
