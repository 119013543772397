import styled from '@emotion/styled';
import React from 'react';
import AButton from '../../components/Button';
import { Link, useNavigate } from 'react-router-dom';
import Footer from '../Footer';
import Header from '../Header';
import { isProd } from '../../utils/helpers';
import YouTubeEmbed from '../../components/YouTubeEmbed';

const BGImage = require('../../../src/assets/banner-bg-shape.jpg');
const CardIcon1 = require('../../assets/Cardicon1.png');
const CardIcon2 = require('../../assets/Cardicon2.png');
const CardIcon3 = require('../../assets/Cardicon3.png');
const about = require('../../assets/about3.png');
const body = require('../../assets/body.png');

const RootContainer = styled.div(() => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  width: '100%',
}));

const HeroContainer = styled.div(() => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  margin: 'auto',
  background: `url(${BGImage})`,
  backgroundSize: '100% 100%',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  paddingBottom: 100,
}));

const Container = styled.div(() => ({
  width: '90%',
  margin: 'auto',
}));

const Body = styled.div(() => ({
  display: 'flex',
  paddingTop: 200,
  justifyContent: 'space-between',
}));

const Content = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: 636,
}));

const ContentTitle = styled.div(() => ({
  fontSize: 67,
  lineHeight: 1.1,
  fontFamily: 'Dosis,sans-serif',
  color: '#212529',
}));

const ContentSubtitle = styled.div(() => ({
  fontSize: 38,
  color: '#57647c',
  fontFamily: 'Open Sans,sans-serif',
  paddingTop: 25,
  fontWeight: 500,
}));

const ContentBody = styled.div(() => ({
  fontSize: 22,
  fontFamily: 'Open Sans,sans-serif',
  paddingTop: 25,
  width: 600,
  fontWeight: 500,
}));

const CardSection = styled.div(() => ({
  paddingTop: '100px',
  paddingBottom: '110px',
}));

const Text = styled.div(() => ({
  fontSize: 20,
  color: 'rgb(87, 100, 124)',
  textAlign: 'center',
  margin: 'auto',
  width: 550,
  lineHeight: '23px',
  fontFamily: 'Open Sans,sans-serif',
}));

const Title = styled.div(() => ({
  fontWeight: 900,
  lineHeight: 1.2,
  color: 'rgb(33, 37, 41)',
  fontSize: 40,
  display: 'flex',
  justifyContent: 'center',
  fontFamily: 'Dosis,sans-serif',
}));

const CardContainer = styled.div(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  gap: 20,
}));

const Card = styled.div(() => ({
  padding: 35,
  display: 'flex',
  flexDirection: 'column',
  gap: 30,
  width: 400,
  borderRadius: 10,
  background: 'linear-gradient(to left bottom,#dee4f3,#e7eaf6,#f0f1f9,#f8f8fc,#fff)',
}));

const Heading = styled.div(() => ({
  fontSize: 23,
  fontWeight: 800,
  ':hover': {
    color: '#FF4800',
    transitionDuration: '0.1s',
  },
  fontFamily: 'Dosis,sans-serif',
  transition: '.5s',
  lineHeight: 1.2,
}));

const CardText = styled.div(() => ({
  fontSize: '18px',
  color: 'rgb(87, 100, 124)',
  lineheight: '100px',
  fontFamily: 'Open Sans,sans-serif',
}));

const Approach = styled.div(() => ({
  fontSize: '40px',
  fontWeight: 700,
  textTransform: 'capitalize',
  fontFamily: 'Dosis,sans-serif',
  lineHeight: 1.3,
  paddingBottom: 18,
}));

const ApproachText = styled.div(() => ({
  color: '#57647c',
  paddingBottom: 13,
  fontSize: 18,
  lineHeight: 1.8,
  fontFamily: 'Open Sans,sans-serif',
}));

const TitleTextContainer = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
}));

const CardBody = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
}));

const Solution = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 60,
}));

const ButtonGroup = styled.div(() => ({
  display: 'flex',
  gap: 20,
  marginTop: 30,
  alignItems: 'center',
}));

const Home = () => {
  const navigate = useNavigate();
  return (
    <RootContainer>
      <Header />
      <HeroContainer>
        <Container style={{ position: 'relative' }}>
          <Body>
            <Content>
              <ContentTitle>Coderbotics AI: Refactoring Automation at Scale</ContentTitle>
              <ContentSubtitle>
                T
                <sub>
                  <strong>1</strong>
                </sub>{' '}
                &le; (½)T
                <sub>
                  <strong>0</strong>
                </sub>
              </ContentSubtitle>
              <ContentBody>
                Coderbotics automates and accelerates your software development by more than 100% so your developers can
                focus on creating new capabilities instead of tedious code remediations and migrations.
              </ContentBody>
              <ButtonGroup>
                {!isProd() && <AButton height={42} name="Login" width={100} onClick={() => navigate('/login')} />}
                {!isProd() && (
                  <Link
                    to={'/register'}
                    style={{
                      textDecoration: 'none',
                      cursor: 'pointer',
                      color: '#ff4800',
                      fontFamily: 'Dosis,sans-serif',
                    }}
                  >
                    Register
                  </Link>
                )}
              </ButtonGroup>
            </Content>
            <img src={body} alt="Logo" width="40%" height="40%" />
          </Body>
        </Container>
      </HeroContainer>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <AButton
          height={42}
          name="Join the Waitlist"
          width={150}
          onClick={() => window.open('https://forms.gle/MRWfbYkjHUqL4U368', '_blank')}
        />
      </div>
      <CardSection>
        <Container>
          <YouTubeEmbed videoEmbedUrl="https://www.youtube.com/embed/_cyjtAz69cM?si=fAyfQ3Gf3jMeyZ0n" />
          <Solution>
            <TitleTextContainer>
              <Title>What's Different?</Title>
              <Text>
                We offer a unique approach to refactoring automation that is tailored to your needs and coding
                standards.
              </Text>
            </TitleTextContainer>
            <CardContainer>
              <Card>
                <CardBody>
                  <Heading>Developer "assisted"</Heading>
                  <CardText>
                    We expect that developers will still verify and accept the migrations. While we offer 1-click, you
                    still have the final say before you merge anything.
                  </CardText>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <Heading>Minimized hallucinations</Heading>
                  <CardText>
                    We use a detailed code generation methods to process your code more deterministically than with the
                    currently available generative AI techniques.
                  </CardText>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <Heading>Tailored for You</Heading>
                  <CardText>
                    We use special SoTA techniques to tailor the migration to your coding and security standards.
                  </CardText>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <Heading>Test Ready</Heading>
                  <CardText>
                    We generate any missing unit tests to provide a comprehensive before-after picture of the process.
                  </CardText>
                </CardBody>
              </Card>
            </CardContainer>
            <TitleTextContainer style={{ marginTop: '100px' }}>
              <Title>Key Use Cases</Title>
              <Text>
                We offer a unique approach to refactoring automation that is tailored to your needs and coding
                standards.
              </Text>
            </TitleTextContainer>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 30 }}>
              {[
                {
                  title: 'Upgrade to NoSQL in 3 weeks',
                  content:
                    'If you are putting off your NoSQL move because of the time it takes to migrate your app, then look no further. <br/><br/>While tools exist for database migration itself, migrating the application is a painful and time consuming process.<br/><br/>Coderbotics uses a combination of automation & AI to reliably:<br/><br/>1. Migrate your application<br/>2.Generate missing unit tests<br/>3.Runs the tests and present a before-after snapshot<br/><br/>Your developers can then inspect, wrap up, and do what only humans can!<br/><br/>Minimal hallucinations. Multiple NoSQL platforms supported.<br/><br/>Experience simpler, smarter migrations.',
                  image: CardIcon1,
                },
                {
                  title: 'Cloud Migration of Legacy Apps',
                  content:
                    'If you are migrating a legacy app and don’t want to do a simple lift and shift, then Coderbotics can help.<br/><br/>Coderbotics modernizes your application so that it follows an API based service oriented architecture.<br/><br/>Eliminate tech debt when you migrate.',
                  image: CardIcon2,
                },
                {
                  title: 'Cleaner, More Standard Code',
                  content:
                    'This service is for you if:<br/>1.You have multiple developers on your team<br/>2.The checked in code is not always aligned to standards you want<br/>3.Developers often forget to write unit tests<br/><br/>Let Coderbotics handle it for you.<br/><br/>Developers spend (and hate) an average of 10-17 hours a week refactoring and cleaning up their code base so it can meet the desired standards for consistency across the team.<br/><br/>With Coderbotics, all you have to do is to:<br/>1.Check in your code<br/>3.Coderbotics cleans & refactors, and generates & runs unit tests<br/>4.Verify and accept the branch<br/><br/>Happy developers. More innovation.',
                  image: CardIcon3,
                },
              ].map((card, index) => (
                <div
                  key={index}
                  style={{
                    width: '60%',
                    padding: 30,
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                    borderRadius: 10,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                    gap: 20,
                    background: 'linear-gradient(to left bottom,#dee4f3,#e7eaf6,#f0f1f9,#f8f8fc,#fff)',
                  }}
                >
                  <h3 style={{ fontSize: 24, fontWeight: 700, fontFamily: 'Dosis,sans-serif', margin: 0 }}>
                    {card.title}
                  </h3>
                  <img src={card.image} alt={card.title} width={60} height={60} />
                  <div
                    style={{
                      fontSize: 22,
                      fontFamily: 'Open Sans,sans-serif',
                      color: '#57647c',
                      margin: 0,
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: 20,
                    }}
                  >
                    <div dangerouslySetInnerHTML={{ __html: card.content }} style={{ textAlign: 'left' }} />
                  </div>
                  <AButton
                    height={42}
                    name="Join the Waitlist"
                    width={150}
                    onClick={() => window.open('https://forms.gle/MRWfbYkjHUqL4U368', '_blank')}
                  />
                </div>
              ))}
            </div>
            <YouTubeEmbed videoEmbedUrl="https://www.youtube.com/embed/SPotvuu2xUg?si=_jlhFvZWaibDOjT2" />
            <CardContainer>
              <Card>
                <CardBody>
                  <Heading>You Choose Your LLM</Heading>
                  <CardText>
                    <div>
                      We support the LLM or platform you want to use:
                      <ul style={{ paddingLeft: 20 }}>
                        <li>OpenAI</li>
                        <li>GPT</li>
                        <li>Anthropic</li>
                        <li>Claude</li>
                        <li>LLaMA</li>
                        <li>Gemini</li>
                        <li>And more</li>
                      </ul>
                    </div>
                  </CardText>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <Heading>Choose Your Cloud</Heading>
                  <CardText>
                    Align with your security strategy:
                    <ul style={{ paddingLeft: 20 }}>
                      <li>On premise</li>
                      <li>AWS</li>
                      <li>Azure</li>
                      <li>Groq</li>
                      <li>Google Cloud</li>
                    </ul>
                  </CardText>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <Heading>Fully Private</Heading>
                  <CardText>
                    The generated code, results and test cases are always within your account. We do not copy or store
                    anything outside on that.
                  </CardText>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <Heading>Full Control</Heading>
                  <CardText>
                    You have full control of the parameters you need:
                    <ul style={{ paddingLeft: 20 }}>
                      <li>1-Click</li>
                      <li>Step by step</li>
                      <li>Multiple config parameters</li>
                    </ul>
                  </CardText>
                </CardBody>
              </Card>
            </CardContainer>
          </Solution>

          <div
            style={{
              marginTop: 100,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              gap: 40,
            }}
          >
            <div style={{ width: 660 }}>
              <img src={about} alt="about" />
            </div>
            <div
              style={{
                width: 660,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <Approach>About Coderbotics</Approach>
              <ApproachText>
                We are a team of technologists who want to maximize the use of AI for good.
                <br />
                <br />
                And what's better than freeing you up to focus on launching new stuff that your business needs, and use
                the money you would have spent on the work no one likes.
              </ApproachText>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <AButton
              height={42}
              name="Join the Waitlist"
              width={150}
              onClick={() => window.open('https://forms.gle/MRWfbYkjHUqL4U368', '_blank')}
            />
          </div>
        </Container>
      </CardSection>
      <Footer />
    </RootContainer>
  );
};

export default Home;
