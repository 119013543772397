import React from 'react';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Welcome from './pages/Welcome';
import AddRepository from './pages/AddRepository';
import Repository from './pages/Repository';
import Information from './pages/Information';
import UploadFile from './pages/UploadFile';
import Loading from './pages/Loading';
import Login from './pages/Login';
import Register from './pages/Register';
import styled from '@emotion/styled';
import Home from './pages/Home';
import Contact from './pages/Contact';
import NotFound from './pages/NotFound';
import Profile from './pages/Profile';
import User from './pages/User';
import { isProd } from './utils/helpers';

const Container = styled.div({
  display: 'flex',
  height: '100vh',
});

const App = () => {
  return (
    <BrowserRouter>
      <Container>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<NotFound />} />
          {!isProd() && <Route path="/login" element={<Login />} />}
          {!isProd() && <Route path="/register" element={<Register />} />}
          {!isProd() && <Route path="/welcome" element={<Welcome />} />}
          {!isProd() && <Route path="/repository" element={<Repository />} />}
          {!isProd() && <Route path="/repository/:repositoryId/information" element={<Information />} />}
          {!isProd() && <Route path="repository/:repositoryId/add-schema" element={<UploadFile />} />}
          {!isProd() && <Route path="/add-repository" element={<AddRepository />} />}
          {!isProd() && <Route path="/loading" element={<Loading />} />}
          {!isProd() && <Route path="/profile" element={<Profile />} />}
          {!isProd() && <Route path="/users" element={<User />} />}
        </Routes>
      </Container>
    </BrowserRouter>
  );
};

export default App;
