export const repositoryStatusDisplayMap = new Map<number, string>([
  [0, 'Setting Up'],
  [1, 'Starting Execution'],
  [2, 'Cloning'],
  [3, 'Calculating Initial Stats'],
  [4, 'Pending Execution'],
  [5, 'Generating Initial Tests'],
  [6, 'Converting Code'],
  [7, 'Generating Final Tests'],
  [8, 'Calculating Final Stats'],
  [9, 'Done'],
  [10, 'Error'],
]);
