import { Button, Table, Typography, Layout as AntLayout, theme, Modal, TableProps } from 'antd';
import React, { useEffect, useState } from 'react';

import { useGetRepositoryQuery, useGetRepositoryUserByIdQuery } from '../../redux/api/repository';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getDateAndTime } from '../../utils/getDateTime';
import { repositoryStatusDisplayMap } from '../../utils/repositoryHelper';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useCookies } from 'react-cookie';
import withAuthentication from '../../HOC/withAuthentication';
import { decodeToken } from '../../utils/tokenutil';
import Layout from '../../layout';

const { Header, Content } = AntLayout;

const { Title } = Typography;

function Repository() {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const [cookies] = useCookies(['token']);

  const { data: repositories } = useGetRepositoryQuery();

  // const { data: repositories } = useGetRepositoryUserByIdQuery()

  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(null);
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const pageParam = new URLSearchParams(location.search).get('page');
    setCurrentPage(pageParam ? parseInt(pageParam, 10) : 1);
  }, [location.search]);

  if (repositories === undefined) {
    return null;
  }

  const pageSize = 10;
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const displayedRepositories = repositories.slice(startIndex, endIndex);

  const repositoryDataSource = displayedRepositories.map((repository, index) => ({
    index: startIndex + 1 + index,
    id: repository.id,
    git_url: repository.git_url,
    created_date: getDateAndTime(repository.created_date),
    execution_status: repositoryStatusDisplayMap.get(repository.execution_status),
    error: repository.error,
    pr_url: repository.pr_url,
  }));

  const handleAddRepository = () => {
    navigate('/add-repository');
  };

  const columns: TableProps<any>['columns'] = [
    {
      title: 'No.',
      dataIndex: 'index',
      key: 'index',
    },
    {
      title: 'GitHub URL',
      dataIndex: 'git_url',
      key: 'gitUrl',
      render: (text: string, record: any) => (
        <Link target="_blank" to={text}>
          {text}
        </Link>
      ),
    },
    {
      title: 'PR URL',
      dataIndex: 'pr_url',
      key: 'pr_url',
      render: (text: string, record: any) => (
        <Link target="_blank" to={text}>
          {text}
        </Link>
      ),
    },
    {
      title: 'Created Date',
      dataIndex: 'created_date',
      key: 'createdDate',
    },
    {
      title: 'Execution Status',
      dataIndex: 'execution_status',
      key: 'execution_status',
    },
    {
      title: 'Error Log',
      dataIndex: 'error',
      key: 'error',
      render: (error: string, record: any) => (
        <>
          {error ? (
            <Button type="text" onClick={() => setIsModalOpen(record.id)}>
              View Error
            </Button>
          ) : null}

          <Modal
            title="Error"
            key={record.id}
            open={isModalOpen === record.id}
            onCancel={() => setIsModalOpen(null)}
            footer={[
              null,
              <Button type="primary" onClick={() => setIsModalOpen(null)} key={1}>
                Ok
              </Button>,
            ]}
          >
            <div style={{ height: '450px', overflowY: 'auto' }}>{error}</div>
          </Modal>
        </>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (data: any, record: any) => (
        <Link to={`/repository/${record.id}/information`}>
          <ExclamationCircleOutlined />
        </Link>
      ),
    },
  ];

  return (
    <Layout>
      <Header
        style={{
          padding: 0,
          background: colorBgContainer,
          alignItems: 'center',
          display: 'flex',
          paddingLeft: 20,
          justifyContent: 'space-between',
          paddingRight: 20,
        }}
      >
        <Title level={2} style={{ margin: 0 }}>
          Repository
        </Title>
        <Button type="primary" onClick={handleAddRepository}>
          Add Repository
        </Button>
      </Header>
      <Content style={{ margin: '0 16px' }}>
        <Table
          dataSource={repositoryDataSource}
          columns={columns}
          pagination={{
            pageSize,
            total: repositories.length,
            current: currentPage,
            onChange: (page) => {
              navigate(`?page=${page}`);
            },
          }}
        />
      </Content>
    </Layout>
  );
}

export default withAuthentication(Repository);
