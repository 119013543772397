import { Button, Input, Layout as AntdLayout, Typography, Form, Select } from 'antd';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled from '@emotion/styled';
import { useCreateRepositoryMutation } from '../../redux/api/repository';
import { useNavigate } from 'react-router';
import withAuthentication from '../../HOC/withAuthentication';
import Layout from '../../layout';
import TextArea from 'antd/es/input/TextArea';
import Password from 'antd/es/input/Password';

type FormData = {
  github_token: string;
  git_url: string;
  git_branch_name: string;
  repo_lang_version: string;
  num_files_to_analyze: number;
  env_vars: string;
  repo_lang: string;
  test_directory: string;
  repo_lang_dep_manager: string;
  database_name: string;
  current_database_name: string;
  target_database_name: string;
  repo_schema: string;
};

const Error = styled.div(() => ({ marginBottom: 10, color: 'red' }));

const FieldContainer = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 6,
}));

const Container = styled.div(() => ({
  padding: 20,
}));

const FieldName = styled.div(() => ({
  color: '#000000',
  fontSize: 16,
}));

const AddRepository = () => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getValues,
  } = useForm<FormData>({
    defaultValues: {
      github_token: '',
      git_branch_name: '',
      git_url: '',
      repo_lang_version: '',
      env_vars: '',
      repo_lang: 'python',
      test_directory: 'tests',
      repo_lang_dep_manager: '',
      database_name: 'mongodb',
      current_database_name: 'postgres',
      target_database_name: 'mongodb',
      repo_schema: '',
    },
  });
  const { Title } = Typography;
  const { Header } = AntdLayout;
  const navigate = useNavigate();
  const [createRepository] = useCreateRepositoryMutation();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data: FormData) => {
    setLoading(true);
    // strip / from the end of the url
    if (data.git_url.endsWith('/')) {
      data.git_url = data.git_url.slice(0, -1);
    }
    const repo = await createRepository(data).unwrap();
    // localStorage.setItem("taskId", JSON.stringify(task.id));
    navigate(`/repository/${repo.id}/information`);
  };

  return (
    <Layout>
      <Header
        style={{
          padding: 0,
          background: '#ffffff',
          alignItems: 'center',
          display: 'flex',
          paddingLeft: 20,
          justifyContent: 'space-between',
          paddingRight: 20,
        }}
      >
        <Title level={2} style={{ margin: 0 }}>
          Add Repository
        </Title>
      </Header>

      <Container>
        <Form name="basic" style={{ maxWidth: 500 }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 22 }}>
            <FieldContainer>
              <FieldName>GitHub Repository URL</FieldName>
              <Controller
                name="git_url"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <>
                    <Input {...field} placeholder="https://github.com/owner/repo" />
                    {errors.git_url && <Error>GitHub Repository URL is required.</Error>}
                  </>
                )}
              />
            </FieldContainer>
            <FieldContainer>
              <FieldName>GitHub Token</FieldName>
              <Controller
                name="github_token"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <>
                    <Password {...field} placeholder="github_pat_XXXXXX..." />
                    {errors.github_token && <Error>GitHub Token is required.</Error>}
                  </>
                )}
              />
            </FieldContainer>
            <FieldContainer>
              <FieldName>Branch Name</FieldName>
              <Controller
                name="git_branch_name"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <>
                    <Input {...field} placeholder="feature-branch-xyz" />
                    {errors.git_branch_name && <Error>Branch Name is required.</Error>}
                  </>
                )}
              />
            </FieldContainer>

            <FieldContainer>
              <FieldName>Language</FieldName>
              <Controller
                name="repo_lang"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <>
                    <Select defaultValue="python" options={[{ value: 'python' }, { value: 'java' }]} {...field} />
                    {errors.repo_lang && <Error>Language is required.</Error>}
                  </>
                )}
              />
            </FieldContainer>

            <FieldContainer>
              <FieldName>Current Database Name</FieldName>
              <Controller
                name="current_database_name"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <>
                    <Select
                      defaultValue="postgres"
                      options={[{ value: 'mongodb' }, { value: 'cockroachdb' }, { value: 'postgres' }]}
                      {...field}
                    />
                    {errors.current_database_name && <Error>Database Name is required.</Error>}
                  </>
                )}
              />
            </FieldContainer>

            <FieldContainer>
              <FieldName>Target Database Name</FieldName>
              <Controller
                name="target_database_name"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <>
                    <Select
                      defaultValue="mongodb"
                      options={[{ value: 'mongodb' }, { value: 'cockroachdb' }, { value: 'postgres' }]}
                      {...field}
                    />
                    {errors.target_database_name && <Error>Database Name is required.</Error>}
                  </>
                )}
              />
            </FieldContainer>

            <FieldContainer>
              <FieldName>Database Schema</FieldName>
              {/* input */}
              <Controller
                name="repo_schema"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <>
                    <TextArea {...field} placeholder="Database Schema" autoSize={{ minRows: 3, maxRows: 5 }} />
                  </>
                )}
              />
            </FieldContainer>

            {/* <FieldContainer>
              <FieldName>Python Version</FieldName>
              <Controller
                name="repo_lang_version"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <>
                    <Select
                      defaultValue="3.12.2"
                      options={[
                        { value: '3.12.2' },
                        { value: '3.11.9' },
                        { value: '3.10.14' },
                        { value: '3.9.19' },
                        { value: '3.8.19' },
                      ]}
                      {...field}
                    />
                    {errors.repo_lang_version && <Error>Python Version is required.</Error>}
                  </>
                )}
              />
            </FieldContainer> */}

            {/* <FieldContainer>
              <FieldName>Number of Files to Analyze</FieldName>
              <Controller
                name="num_files_to_analyze"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <>
                    <Input {...field} placeholder="5" />
                    {errors.num_files_to_analyze && <Error>Number of Files to Analyze is required.</Error>}
                  </>
                )}
              />
            </FieldContainer>

            <FieldContainer>
              <FieldName>Environment Variables for Testing</FieldName>
              <Controller
                name="env_vars"
                control={control}
                render={({ field }) => (
                  <>
                    <TextArea {...field} placeholder="TEST=True" autoSize={{ minRows: 3, maxRows: 5 }} />
                  </>
                )}
              />
            </FieldContainer> */}

            <Button type="primary" onClick={handleSubmit(onSubmit)} style={{ width: 100 }} loading={loading}>
              Next
            </Button>
          </div>
        </Form>
      </Container>
    </Layout>
  );
};

export default withAuthentication(AddRepository);
