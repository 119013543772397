import { jwtDecode } from 'jwt-decode';
import { TokenData } from '../types/token';

export const isTokenExpired = (token: string) => {
  const decoded: TokenData = decodeToken(token);
  const timeSeconds = Date.now() / 1000;
  return decoded.exp <= timeSeconds;
};

export const decodeToken = (token: string): TokenData => {
  return jwtDecode(token);
};
