import React from 'react';
import Layout from '../../layout';
import { Table, Layout as AntLayout, Typography, Button, TableProps } from 'antd';
import { useGetAllUserQuery } from '../../redux/api/user';
import withAuthentication from '../../HOC/withAuthentication';

export interface GetUser {
  id: number | string;
  name: string;
  email: string;
}
const { Header, Content } = AntLayout;
const { Title } = Typography;

const User = () => {
  const { data: getAllUser } = useGetAllUserQuery();

  const userDataSource = getAllUser?.map((user, index) => ({
    index: index + 1,
    name: user.name,
    email: user.email,
  }));

  const columns: TableProps<any>['columns'] = [
    {
      title: 'Id',
      dataIndex: 'index',
      key: 'index',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
  ];

  return (
    <Layout>
      <Header
        style={{
          padding: 0,
          background: '#ffffff',
          alignItems: 'center',
          display: 'flex',
          paddingLeft: 20,
          justifyContent: 'space-between',
          paddingRight: 20,
        }}
      >
        <Title level={2} style={{ margin: 0 }}>
          Users
        </Title>
      </Header>
      <Content style={{ margin: '0 16px' }}>
        <Table dataSource={userDataSource} columns={columns} />
      </Content>
    </Layout>
  );
};

export default withAuthentication(User);
