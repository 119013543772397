import React from 'react';

interface YouTubeEmbedProps {
  videoEmbedUrl: string;
  width?: number;
  height?: number;
}

const YouTubeEmbed: React.FC<YouTubeEmbedProps> = ({ videoEmbedUrl, width = 640, height = 360 }) => {
  return (
    <div className="youtube-embed" style={{ padding: '20px 0', width: '100%', textAlign: 'center' }}>
      <iframe
        width={width}
        height={height}
        src={`${videoEmbedUrl}`}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default YouTubeEmbed;
