import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { GetUser } from '../../types/user';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers: Headers, { getState }: any) => {
      const state = getState();
      if (state) {
        const token = state.auth.user.token;
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['user'],

  endpoints: (builder) => ({
    getAllUser: builder.query<GetUser[], void>({
      query: () => ({ url: '/user' }),
      providesTags: ['user'],
    }),
    getUser: builder.query<GetUser, void>({
      query: () => ({ url: '/user/current' }),
      providesTags: ['user'],
    }),
    getUserById: builder.query({
      query: (id) => `user/${id}`,
      providesTags: ['user'],
    }),
    updateUser: builder.mutation({
      query: (input) => ({
        url: 'user',
        method: 'PUT',
        body: input,
      }),
      invalidatesTags: ['user'],
    }),
    updateUserPassword: builder.mutation({
      query: (input) => ({
        url: 'user/change_password',
        method: 'PUT',
        body: input,
      }),
      invalidatesTags: ['user'],
    }),
  }),
});

export const {
  useGetAllUserQuery,
  useGetUserByIdQuery,
  useGetUserQuery,
  useUpdateUserMutation,
  useUpdateUserPasswordMutation,
} = userApi;
