import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { CreateEnvironment, Environment } from '../../types/environment';

export const environmentApi = createApi({
  reducerPath: 'environmentApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers: Headers, { getState }: any) => {
      const state = getState();
      if (state) {
        const token = state.auth.user.token;
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['environment'],
  endpoints: (builder) => ({
    getEnvironment: builder.query<Environment[], void>({
      query: () => '/environment',
      providesTags: ['environment'],
    }),
    createEnvironment: builder.mutation<{ id: string }, CreateEnvironment>({
      query: (input) => ({
        url: `/environment`,
        method: 'POST',
        body: input,
      }),
      invalidatesTags: ['environment'],
    }),
  }),
});

export const { useGetEnvironmentQuery, useCreateEnvironmentMutation } = environmentApi;
