import styled from '@emotion/styled';
import React from 'react';
import TweeterIcon from '../../components/icons/Tweeter';
import { Link } from 'react-router-dom';
import LinkedInIcon from '../../components/icons/LinkedIn';
import MailIcon from '../../components/icons/Mail';
const Logo = require('../../../src/assets/coderbotic.png');

const RootContainer = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  backgroundColor: '#15171a',
  paddingTop: 100,
  paddingBottom: 100,
}));

const Container = styled.div(() => ({
  width: '100%',
  margin: 'auto',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));

const DetailContainer = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  padding: '0px 10px',
  zIndex: 2,
}));

const Detail = styled.div(() => ({
  color: '#fff',
  lineHeight: 1.8,
  fontFamily: 'Open Sans,sans-serif',
  paddingTop: 18,
  fontSize: 15,
  paddingBottom: 20,
}));

const SocialIcon = styled.div(() => ({
  display: 'flex',
  gap: 10,
  justifyContent: 'flex-start',
}));

const SIcon = styled.div(() => ({
  borderRadius: '50%',
  position: 'relative',
  height: 35,
  width: 35,
  background: '#212529',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  ':hover': {
    color: '#fff',
    borderColor: '#ff4800',
    backgroundColor: '#ff4800',
  },
  cursor: 'pointer',
  transition: '.5s',
}));

const Title = styled.div(() => ({
  color: '#fff',
  fontSize: 23,
  fontFamily: ' Dosis,sans-serif',
}));

const Ul = styled.div(() => ({
  marginBottom: 30,
}));

const Li = styled.div(() => ({
  transition: '.5s',
  ':hover': {
    color: '#ff4800',
  },
  cursor: 'pointer',
  fontSize: 14,
  marginBottom: 12,
  color: '#fff',
  lineHeight: 1.7,
}));

const ContactTitle = styled.span(() => ({
  marginRight: 5,
  color: '#fff',
  fontWeight: 700,
  fontFamily: 'Open Sans,sans-serif',
}));

const Contact = styled.div(() => ({
  fontSize: 14,
  marginBottom: 12,
  color: '#fff',
  lineHeight: 1.7,
}));

const Border = styled.div(() => ({
  border: '1px solid #ff4800',
  width: 40,
  marginTop: 5,
  marginBottom: 20,
}));

const Footer = () => {
  const emailAddress = 'support@coderbotics.ai';

  return (
    <RootContainer>
      <Container>
        <DetailContainer>
          <div>
            <img src={Logo} alt="logo" width={50} style={{ borderRadius: '50%' }} />
          </div>
          <Detail>We are a team of technologists who want to maximize the use of AI for good</Detail>
          <div>
            <SocialIcon>
              <Link
                style={{
                  textDecoration: 'none',
                  cursor: 'pointer',
                  fontSize: 14,
                  marginBottom: 12,
                  color: '#fff',
                  lineHeight: 1.7,
                }}
                to={'https://twitter.com/coderbotics_ai'}
                target="_blank"
                rel="noreferrer noopener"
              >
                <SIcon>
                  <TweeterIcon color="#fff" size={14} />
                </SIcon>
              </Link>
              <Link
                style={{
                  textDecoration: 'none',
                  cursor: 'pointer',
                  fontSize: 14,
                  marginBottom: 12,
                  color: '#fff',
                  lineHeight: 1.7,
                }}
                to={'https://www.linkedin.com/company/coderbotics-ai/'}
                target="_blank"
                rel="noreferrer noopener"
              >
                <SIcon>
                  <LinkedInIcon color="#fff" size={14} />
                </SIcon>
              </Link>
              <Link
                style={{
                  textDecoration: 'none',
                  cursor: 'pointer',
                  fontSize: 14,
                  marginBottom: 12,
                  color: '#fff',
                  lineHeight: 1.7,
                }}
                to={`mailto:${emailAddress}`}
                target="_blank"
                rel="noreferrer noopener"
              >
                <SIcon>
                  <MailIcon color="#fff" size={14} />
                </SIcon>
              </Link>
            </SocialIcon>
          </div>
        </DetailContainer>
      </Container>
    </RootContainer>
  );
};

export default Footer;
