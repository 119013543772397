import styled from '@emotion/styled';
import React from 'react';
import ContactForm from './ContactForm';
import { Link } from 'react-router-dom';
import Footer from '../Footer';
import Header from '../Header';
import AButton from '../../components/Button';
const BgImage = require('../../assets/page-bg.jpg');
const image = require('../../assets/contact.png');

const RootContainer = styled.div(() => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  paddingBottom: 110,
  margin: 'auto',
}));

const Heading = styled.div(() => ({
  color: 'rgb(255, 72, 0)',
  marginBottom: '5px',
  fontWeight: 600,
  fontSize: '32px',
  display: 'flex',
  justifyContent: 'center',
  fontFamily: 'Dosis,sans-serif',
}));

const Title = styled.div(() => ({
  fontWeight: 500,
  lineHeight: ' 1.2',
  color: 'rgb(33, 37, 41)',
  fontSize: '38px',
  display: 'flex',
  justifyContent: 'center',
  fontFamily: 'Dosis,sans-serif',
}));

const Container = styled.div(() => ({
  width: '100%',
  margin: 'auto',
}));

const FormContainer = styled.div(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignContent: 'space-between',
  justifyItems: 'center',
  width: '100%',
}));

const Text = styled.div(() => ({
  fontSize: 22,
  color: '#57647c',
  textAlign: 'center',
  margin: 'auto',
  width: 550,
  lineHeight: '23px',
  fontFamily: 'Open Sans,sans-serif',
}));
const HeadingContainer = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
}));

const ContactDetails = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  margin: 'auto',
  gap: 15,
  fontFamily: 'Dosis,sans-serif',
  paddingTop: 30,
}));

const Label = styled.div(() => ({
  fontSize: '26px',
  fontWeight: 700,
  color: '#212529',
  marginBottom: 15,
}));

const Number = styled.div(() => ({
  display: 'flex',
  justifyContent: 'center',
  color: '#ff4800',
  fontSize: 40,
  fontWeight: 700,
  ':hover': {
    color: '#4ac728',
  },
}));

const Or = styled.div(() => ({
  display: 'flex',
  justifyContent: 'center',
  color: '#57647c',
  marginTop: 8,
  marginBottom: 0,
  fontSize: 18,
  fontWeight: 500,
}));

const Email = styled.div(() => ({
  display: 'flex',
  justifyContent: 'center',
  color: '#4ac728',
  fontSize: 40,
  fontWeight: 700,
  ':hover': {
    color: '#ff4800',
  },
  cursor: 'pointer',
}));

const SocialIcon = styled.div(() => ({
  display: 'flex',
  gap: 10,
  justifyContent: 'center',
}));

const SIcon = styled.div(() => ({
  borderRadius: '50%',
  position: 'relative',
  height: 35,
  width: 35,
  background: '#ffffff',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: '1px solid #dadada',
  ':hover': {
    color: '#fff',
    borderColor: '#ff4800',
    backgroundColor: '#ff4800',
  },
  cursor: 'pointer',
}));

const ContactSubTitle = styled.span(() => ({
  fontFamily: 'Dosis,sans-serif',
  color: 'white ',
}));

const ContactTitle = styled.div(() => ({
  color: '#fff',
  fontSize: 40,
  fontFamily: 'Dosis,sans-serif',
  paddingTop: 150,
}));

const HomeTitle = styled.span(() => ({
  color: '#ff4800',
  fontFamily: 'Dosis,sans-serif',
  marginRight: 14,
}));

const HeaderContainer = styled.div(() => ({
  width: '90%',
  margin: 'auto',
}));

const DarkBackground = styled.div(() => ({
  paddingBottom: 240,
  backgroundColor: 'rgba(0,0,0,0.44)',
}));

const ContactHeader = styled.div(() => ({
  background: `url(${BgImage})`,
  display: 'flex',
  flexDirection: 'column',
  paddingTop: 120,
  backgroundRepeat: 'no-repeat',
  marginTop: -120,
  marginBottom: 100,
  position: 'relative',
}));

const Contact = () => {
  const emailAddress = 'support@coderbotics.ai';

  return (
    <div style={{ flexGrow: 1 }}>
      <Header />
      <ContactHeader>
        <DarkBackground>
          <HeaderContainer>
            <ContactTitle>Contact</ContactTitle>
            <Link to={'/'} style={{ textDecoration: 'none' }}>
              <HomeTitle>Home</HomeTitle>
            </Link>
            <ContactSubTitle>Contact</ContactSubTitle>
          </HeaderContainer>
        </DarkBackground>
      </ContactHeader>
      <Container>
        <RootContainer>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              gap: 30,
              flexDirection: 'column',
            }}
          >
            <HeadingContainer>
              <Title>Join the Waitlist and be the first to know</Title>
              <Text>
                We are here to help! Just provide some details about you in the form below, and we will get back to you
                as soon as we can. We do not share your information with anyone.
              </Text>
            </HeadingContainer>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <AButton
                height={42}
                name="Join the Waitlist"
                width={150}
                onClick={() => window.open('https://forms.gle/MRWfbYkjHUqL4U368', '_blank')}
              />
            </div>
            {/* <FormContainer>
              <div
                style={{
                  width: '25%',
                  maxWidth: '395px',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  justifyItems: 'center',
                  margin: 'auto auto auto 1.5%',
                }}
              >
                <img
                  src={image}
                  alt="formImage"
                  width="100%"
                  style={{
                    padding: 'auto',
                    maxWidth: '395px',
                  }}
                />
              </div>
              <div
                style={{
                  width: '75%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  justifyItems: 'center',
                  margin: 'auto 1.5%',
                }}
              >
                <ContactForm />
              </div>
            </FormContainer> */}
            <ContactDetails>
              <Label>Contact Us via Email</Label>
              <Link
                style={{
                  textDecoration: 'none',
                }}
                target="_blank"
                rel="noreferrer noopener"
                to={`mailto:${emailAddress}`}
              >
                <Email>{emailAddress}</Email>
              </Link>
            </ContactDetails>
          </div>
        </RootContainer>
      </Container>
      <Footer />
    </div>
  );
};

export default Contact;
