import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGetRepoSummaryQuery } from '../../redux/api/repository';
import { Button, Card, Select, Spin } from 'antd';
import Title from 'antd/es/typography/Title';
import styled from '@emotion/styled';
import withAuthentication from '../../HOC/withAuthentication';
import ScaningIcon from '../../components/icons/Scaning';
import CloneIcon from '../../components/icons/Clone';
import Layout from '../../layout';
import NotFound from '../NotFound';
import InfoSummary from './InfoSummary';
import { repositoryStatusDisplayMap } from '../../utils/repositoryHelper';
import LoadingIcon from '../../components/icons/Loading';

const icons = [
  { icon: CloneIcon, text: 'Cloning Repository...' },
  { icon: ScaningIcon, text: 'Scanning...' },
];

const Container = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  margin: 20,
  gap: 40,
  overflowY: 'scroll',
}));

const InfoContainer = styled.div(() => ({
  marginBottom: 20,
}));

const LabelValue = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: 10,
}));

const Value = styled.div(() => ({
  fontSize: 18,
  fontWeight: 'bold',
}));

const TestsContainer = styled.div(() => ({
  display: 'flex',
  gap: 20,
  flexDirection: 'column',
}));

const CardContainer = styled.div(() => ({
  display: 'flex',
  gap: 20,
}));

const LibraryContainer = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: 10,
}));

const ActionContainer = styled.div(() => ({
  display: 'flex',
  gap: 10,
  flexDirection: 'column',
}));

const SpinerContent = styled.div(() => ({
  display: 'inline-flex',
  alignItems: 'center',
  flexDirection: 'column',
  padding: 20,
  gap: 10,
}));

const LoadContainer = styled.div(() => ({
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: 30,
}));

const Text = styled.div(() => ({
  color: 'black',
  fontWeight: 'bold',
  fontSize: 30,
}));

const RepoStatusEnum = {
  NOT_STARTED: 0,
  STARTING_EXECUTION: 1,
  CLONING: 2,
  CALCULATING_INITIAL_STATS: 3,
  PENDING_EXECUTION: 4,
  GENERATING_INITIAL_TESTS: 5,
  CONVERTING_CODE: 6,
  GENERATING_FINAL_TESTS: 7,
  CALCULATING_FINAL_STATS: 8,
  DONE: 9,
  ERROR: 10,
};

const Information = () => {
  const { repositoryId } = useParams();
  const [action, setAction] = useState('db_migration');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { data, refetch, isLoading: repositoryLoading } = useGetRepoSummaryQuery(repositoryId);
  const [activeIndex, setActiveIndex] = useState(0);
  const [textChangeCount, setTextChangeCount] = useState(0);

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined = undefined;
    if (data && data?.execution_status < RepoStatusEnum.PENDING_EXECUTION) {
      interval = setInterval(() => {
        refetch();
      }, 5000);
      return () => {
        clearInterval(interval);
      };
    }
    if (data && data?.execution_status >= RepoStatusEnum.PENDING_EXECUTION) {
      setLoading(false);
      clearInterval(interval);
    }
  }, [data, refetch, textChangeCount]);

  if (data === undefined && repositoryLoading === true) return <div>Loading...</div>;

  if (data === undefined && repositoryLoading === false) {
    return <NotFound />;
  }

  const handleChange = (value: string) => {
    setAction(value);
  };

  const handleNext = () => {
    navigate(`/repository/${repositoryId}/add-schema`, { state: { action } });
  };

  return data && loading === true ? (
    <Layout>
      <LoadContainer>
        <div>
          <SpinerContent>
            <LoadingIcon color="black" size={100} />
            <Text>{repositoryStatusDisplayMap.get(data.execution_status) + '...'}</Text>
          </SpinerContent>
        </div>
      </LoadContainer>
    </Layout>
  ) : (
    <Layout>
      <Container>
        {data && (
          <InfoSummary
            summaryData={{
              version: data.version,
              language: data.language,
              num_files_to_analyze: data.num_files_to_analyze,
              git_url: data.git_url,
              pr_url: data.pr_url,
              branch_name: data.branch_name,
              database_name: data.database_name,
              execution_status: data.execution_status,
              dependency_management: data.dependency_management,
              test_env_vars: data.test_env_vars,
              test_directory: data.test_directory,
              error: data.error,
              line: {
                lines_before: data.total_lines_before,
                lines_after: data.total_lines_after,
              },
              file: {
                files_before: data.total_files_before,
                files_after: data.total_files_after,
              },
              function: {
                functions_before: data.total_functions_before,
                functions_after: data.total_functions_after,
              },
              class: {
                class_before: data.total_classes_before,
                class_after: data.total_classes_after,
              },
              code_coverage: {
                coverage_before: data.coverage_before,
                coverage_after: data.coverage_after_migration,
              },
              code_complexity: {
                complexity_before: data.complexity_before,
                complexity_after: data.complexity_after_migration,
              },
              built_in_tests: {
                passed: data.test_count_before,
                passed_percent: data.test_percent_before,
              },
              generated_tests: {
                passed: data.test_count_after_migration,
                passed_percent: data.test_percent_after_migration,
              },
              num_files_before: data.num_files_before,
              num_files_after: data.num_files_after,
              num_lines_before: data.num_lines_before,
              num_lines_after: data.num_lines_after,
            }}
          />
        )}

        {/* <div>
          {libraries && libraries?.length > 0 && (
            <>
              <Title>Dependencies:</Title>
              <Card
                title="Dependencies Info"
                bordered={false}
                style={{ width: 400 }}
              >
                {libraries?.map((library) => {
                  return (
                    <LibraryContainer key={library.name}>
                      <Value>{library.name}:</Value>
                      {library.version}
                    </LibraryContainer>
                  );
                })}
              </Card>
            </>
          )}
        </div> */}

        {data?.execution_status === RepoStatusEnum.PENDING_EXECUTION && (
          <>
            <ActionContainer>
              <Value>Choose Action:</Value>
              <Select
                defaultValue="DB Migration"
                style={{ width: 300 }}
                onChange={handleChange}
                allowClear
                options={[
                  { value: 'db_migration', label: 'DB Migration' },
                  // { value: 'tech_debt', label: 'Tech Debt' },
                  // { value: 'cloud_migration', label: 'Cloud Migration' },
                ]}
              />
            </ActionContainer>

            <Button type="primary" onClick={handleNext} style={{ width: 100 }}>
              Next
            </Button>
          </>
        )}
      </Container>
    </Layout>
  );
};

export default withAuthentication(Information);
