import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const taskListApi = createApi({
  reducerPath: 'taskListApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers: Headers, { getState }: any) => {
      const state = getState();
      if (state) {
        const token = state.auth.user.token;
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['file'],

  endpoints: (builder) => ({
    getFile: builder.query({
      query: () => '/file',
      providesTags: ['file'],
    }),
    getByRepositoryId: builder.query({
      query: (id) => `file/repository/${id}`,
      providesTags: ['file'],
    }),
    getFileById: builder.query({
      query: (id) => `/file/${id}`,
      providesTags: ['file'],
    }),
  }),
});

export const { useGetFileQuery, useGetByRepositoryIdQuery, useGetFileByIdQuery } = taskListApi;
