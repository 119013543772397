import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AButton from '../../components/Button';
import { isProd } from '../../utils/helpers';

const Logo = require('../../../src/assets/coderbotic.png');

const HeaderMenu = styled.div(() => ({
  ':hover': {
    color: '#ff4800',
  },
  color: '#212529',
  cursor: 'pointer',
  transition: 'color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out',
}));

const MenuBar = styled.div(() => ({
  padding: '10px 0px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '90%',
  margin: 'auto',
}));

const Menu = styled.div(() => ({
  display: 'flex',
  gap: 26,
  alignItems: 'center',
  fontFamily: 'Dosis,sans-serif',
}));

const NavbarContainer = styled.div<{ isScrolled: boolean }>(({ isScrolled }) => ({
  background: isScrolled ? 'white' : '',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  transition: 'background-color 0.3s ease,padding 0.3s ease',
  boxShadow: isScrolled ? '0 2px 28px 0 rgba(0,0,0,.06)' : '',
  color: isScrolled ? 'white' : 'black',
}));

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        height: 84,
        width: '100%',
        top: 0,
        zIndex: 5,
        backgroundColor: 'transparent',
      }}
    >
      <NavbarContainer isScrolled={isScrolled}>
        <div style={{ width: '100vw', margin: 'auto' }}>
          <MenuBar>
            <div>
              <Link to="/">
                <img src={Logo} alt="Logo" width="60px" style={{ borderRadius: '50%', zIndex: 1 }} />
              </Link>
            </div>
            <Menu>
              <Link to={'/'} style={{ textDecoration: 'none' }}>
                <HeaderMenu>Home</HeaderMenu>
              </Link>
              <Link to={'/contact'} style={{ textDecoration: 'none' }}>
                <HeaderMenu>Contact</HeaderMenu>
              </Link>
              {!isProd() && (
                <AButton height={42} name="Login" width={100} hoverColor="#ff4800" onClick={() => navigate('/login')} />
              )}
            </Menu>
          </MenuBar>
        </div>
      </NavbarContainer>
    </div>
  );
};

export default Header;
