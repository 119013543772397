import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ContactUs } from '../../types/contact';

export const contactApi = createApi({
  reducerPath: 'contactApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers: Headers, { getState }: any) => {
      const state = getState();
      if (state) {
        const token = state.auth.user.token;
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['contact'],
  endpoints: (builder) => ({
    contactus: builder.mutation<void, ContactUs>({
      query: (input) => ({
        url: `/contact`,
        method: 'POST',
        body: input,
      }),
      invalidatesTags: ['contact'],
    }),
  }),
});

export const { useContactusMutation } = contactApi;
