import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface ICurrentUser {
  email: string;
  token: string;
}

export interface IUserState {
  user: ICurrentUser;
  isAppInitialized: boolean;
}

const initialState: IUserState = {
  user: {
    email: '',
    token: '',
  },
  isAppInitialized: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state: IUserState, action: PayloadAction<ICurrentUser>) => {
      const { email, token } = action.payload;
      state.user.email = email;
      state.user.token = token;
    },
    logout: (state: IUserState, action: PayloadAction<void>) => {
      state.user.email = '';
      state.user.token = '';
    },
    initializedAppDone: (state: IUserState, action: PayloadAction<void>) => {
      state.isAppInitialized = true;
    },
  },
});

export const { setUser, logout, initializedAppDone } = authSlice.actions;
export default authSlice.reducer;
