import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Typography } from 'antd';
import styled from '@emotion/styled';
import { Link, useNavigate } from 'react-router-dom';
import { Register as RegisterType } from '../../types/register';
import useFetch from '../../utils/useFetch';
import { useCookies } from 'react-cookie';
import Header from '../Header';

const FormContainer = styled.div(() => ({
  margin: 'auto',
}));

const Container = styled.div(() => ({
  maxWidth: 300,
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
  padding: 30,
  borderRadius: 8,
  backgroundColor: '#F8F8FF',
}));

const HeaderContainer = styled.div(() => ({
  boxShadow: '0 2px 28px 0 rgba(0,0,0,.06)',
}));

const Register = () => {
  const { Title } = Typography;
  const navigate = useNavigate();
  const [registerError, setRegisterError] = useState<string | null>(null);

  const [cookies] = useCookies(['token']);

  const token = cookies.token;

  useEffect(() => {
    if (token) {
      navigate('/welcome');
    }
  }, [token, navigate]);

  const { execute, loading } = useFetch(`${process.env.REACT_APP_BASE_URL}auth/register`, 'POST', false);

  const onFinish = async (values: RegisterType) => {
    const responseData = await execute(JSON.stringify(values));

    if (responseData.detail !== undefined) {
      setRegisterError(responseData.detail);
    } else {
      navigate('/login');
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
      <HeaderContainer>
        <Header />
      </HeaderContainer>
      <FormContainer>
        <Title level={5}>Register</Title>
        <div style={{ color: 'red', marginBottom: '0.5rem' }}>{registerError}</div>
        <Container>
          <Form
            name="basic"
            layout="vertical"
            labelCol={{ span: 8 }}
            style={{ maxWidth: 600 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Please input your name!' }]}>
              <Input />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  type: 'email',
                  required: true,
                  message: 'Please input your email!',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit">
                Register
              </Button>
            </Form.Item>
          </Form>
          {'Already have an account? '}
          <Link to={'/login'}>
            <b> Login</b>
          </Link>
        </Container>
      </FormContainer>
    </div>
  );
};

export default Register;
