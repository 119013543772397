import { Button, Layout as AntLayout, Typography } from 'antd';
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAddSchemaMutation, useStartExecutionMutation } from '../../redux/api/repository';
import withAuthentication from '../../HOC/withAuthentication';
import Layout from '../../layout';
import TextArea from 'antd/es/input/TextArea';

const Container = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
  paddingLeft: 20,
  paddingRight: 20,
  paddingTop: 20,
}));

const UploadContainer = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: 10,
}));

const DTitle = styled.div(() => ({
  color: '#000000',
  fontSize: 16,
}));

const { Title } = Typography;
const { Content } = AntLayout;

const UploadFile = () => {
  const location = useLocation();
  const locationState = location.state;

  const { Header } = AntLayout;
  const { repositoryId } = useParams();

  const navigate = useNavigate();

  const [addSchema] = useAddSchemaMutation();
  const [startExecution] = useStartExecutionMutation();
  const [schema, setSchema] = useState('');
  const [error, setError] = useState('');

  if (locationState == null || locationState.action === undefined) {
    return <Navigate to={`/repository/${repositoryId}/information`} replace />;
  }

  const schemaUpdate = (schema: string) => {
    if (repositoryId) {
      addSchema({
        repository_id: parseInt(repositoryId),
        schema_contents: schema,
      })
        .unwrap()
        .then((response: any) => {
          startExecution(repositoryId);
          navigate(`/repository/${repositoryId}/information`);
        });
    }
  };

  const handleSchemaUpdate = () => {
    if (repositoryId) {
      schemaUpdate(schema);
    } else {
      setError('Could not locate Repository ID. Please try again.');
    }
  };

  return (
    <Layout>
      <Header
        style={{
          padding: 0,
          background: '#ffffff',
          alignItems: 'center',
          display: 'flex',
          paddingLeft: 20,
          justifyContent: 'space-between',
          paddingRight: 20,
        }}
      >
        <Title level={2} style={{ margin: 0 }}>
          Add Schema
        </Title>
      </Header>

      {locationState.action === 'db_migration' && (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 22 }}>
          <Container>
            <DTitle>Mongo Schema:</DTitle>
            <UploadContainer>
              <TextArea
                placeholder="Paste your Mongo DB schema here"
                style={{ width: 500 }}
                autoSize={{ minRows: 15, maxRows: 20 }}
                onChange={(e) => setSchema(e.target.value)}
              />
            </UploadContainer>
            <div style={{ color: 'red' }}>{error && error}</div>

            <Button type="primary" onClick={handleSchemaUpdate} style={{ width: 150 }}>
              Start Migration
            </Button>
          </Container>
        </div>
      )}
    </Layout>
  );
};

export default withAuthentication(UploadFile);
