import React, { FC, ReactNode, useEffect, useState } from 'react';
import { Layout as AntLayout, Menu } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { useCookies } from 'react-cookie';
import { decodeToken } from '../utils/tokenutil';

const { Footer, Sider } = AntLayout;

interface LayoutSideBarProps {
  children: ReactNode;
}

const Bottom = styled.div(() => ({
  position: 'absolute',
  bottom: 0,
  width: '100%',
}));

const SidebarButton = styled.div(() => ({
  padding: 15,
  color: '#ffffff',
  cursor: 'pointer',
  ':hover': {
    color: '#ff4800',
    borderTop: '1px solid #4169E1',
    borderBottom: '1px solid #4169E1',
  },
}));

const Layout: FC<LayoutSideBarProps> = ({ children }) => {
  const [cookies, setCookie, removeCookie] = useCookies(['token']);

  const user = decodeToken(cookies.token);
  const is_super_admin = user.is_super_admin;

  const navigate = useNavigate();
  let location = useLocation();

  const [current, setCurrent] = useState(
    location.pathname === '/' || location.pathname === '' ? '/repository' : location.pathname,
  );

  useEffect(() => {
    if (location) {
      if (current !== location.pathname) {
        setCurrent(location.pathname);
      }
    }
  }, [location, current]);

  const handleLogout = () => {
    if (cookies) {
      removeCookie('token');
    }
  };

  const handleProfile = () => {
    navigate('/profile');
  };

  return (
    <>
      <AntLayout style={{ minHeight: '100vh' }}>
        <Sider width={'15%'}>
          <div>
            <Menu selectedKeys={[current]} theme="dark" mode="inline" onClick={(e: any) => setCurrent(e.key)}>
              <Menu.Item key="/welcome">
                <Link to="/welcome">Home</Link>
              </Menu.Item>
              <Menu.Item key="/repository">
                <Link to="/repository">Repository</Link>
              </Menu.Item>
              {is_super_admin && (
                <Menu.Item key="/users">
                  <Link to="/users">Users</Link>
                </Menu.Item>
              )}
            </Menu>
          </div>

          <Bottom>
            <SidebarButton onClick={handleProfile}>Profile</SidebarButton>
            <SidebarButton onClick={handleLogout}>Logout</SidebarButton>
          </Bottom>
        </Sider>
        <AntLayout>{children}</AntLayout>
      </AntLayout>
    </>
  );
};

export default Layout;
