import React, { ComponentType, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ProgressIndicator from '../components/ProgressIndicator';
import { useAppDispatch } from '../redux/hooks';
import { decodeToken, isTokenExpired } from '../utils/tokenutil';
import { setUser, initializedAppDone } from '../redux/authSlice';

const withAuthentication = <P extends object>(WrappedComponent: ComponentType<P>) => {
  // eslint-disable-next-line react/display-name
  return (props: P) => {
    const [cookies, setCookie, removeCookie] = useCookies(['token']);
    const dispatch = useAppDispatch();

    const isInitialyzeApp = useSelector((state: any) => state.auth.isAppInitialized);

    useEffect(() => {
      if (cookies.token) {
        const { email } = decodeToken(cookies.token);
        dispatch(setUser({ email: email, token: cookies.token }));
      }
      dispatch(initializedAppDone());
    }, [cookies.token, dispatch]);

    if (isInitialyzeApp === false) {
      return <ProgressIndicator isLoading={!isInitialyzeApp} />;
    }

    if (isInitialyzeApp && cookies.token === undefined) {
      return <Navigate to={'/login'} replace />;
    }

    return <WrappedComponent {...props} />;
  };
};

export default withAuthentication;
