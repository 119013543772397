import React from 'react';
import styled from '@emotion/styled';
import { Card } from 'antd';
import Title from 'antd/es/typography/Title';
import { toSentenceCase } from '../../utils/helpers';
import { Link } from 'react-router-dom';

const notAvailable = 'N/A';

const InfoContainer = styled.div(() => ({
  marginBottom: 20,
}));

const Table = styled.table(() => ({
  width: '100%',
  borderCollapse: 'collapse',
  border: '1px solid #ddd',
}));

const TableHeader = styled.th(() => ({
  padding: 8,
  textAlign: 'left',
  fontWeight: '800',
  textTransform: 'uppercase',
  backgroundColor: '#f2f2f2',
  borderBottom: '1px solid #ddd',
  borderRight: '1px solid #ddd',
}));

const TableRow = styled.tr(() => ({
  '&:nth-child(even)': {
    backgroundColor: '#f2f2f2',
  },
}));

const TableCell = styled.td(() => ({
  padding: '8px',
  borderBottom: '1px solid #ddd',
  borderRight: '1px solid #ddd',
}));

const InfoSummary = (props: { summaryData: any }) => {
  const summaryData = props.summaryData;

  return (
    <div>
      <Title>Summary</Title>
      <InfoContainer>
        <Card bordered={false} style={{ width: '90%' }}>
          <Table>
            <tbody>
              <TableRow>
                <TableCell>Language</TableCell>
                <TableCell>{summaryData.language ?? notAvailable}</TableCell>
              </TableRow>
              {/* <TableRow>
                <TableCell>{toSentenceCase(summaryData.language ?? 'Language')} Version</TableCell>
                <TableCell>{summaryData.version ?? notAvailable}</TableCell>
              </TableRow> */}
              {/* <TableRow>
                <TableCell>Number of files to process</TableCell>
                <TableCell>{summaryData.num_files_to_analyze ?? notAvailable}</TableCell>
              </TableRow> */}
              <TableRow>
                <TableCell>Database Name</TableCell>
                <TableCell>{summaryData.database_name ?? notAvailable}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Branch Name</TableCell>
                <TableCell>{summaryData.branch_name ?? notAvailable}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Git URL</TableCell>
                <TableCell>
                  {summaryData.git_url ? (
                    <Link target="_blank" to={summaryData.git_url}>
                      {summaryData.git_url}
                    </Link>
                  ) : (
                    notAvailable
                  )}
                </TableCell>
              </TableRow>
              {summaryData.pr_url && (
                <TableRow>
                  <TableCell>PR URL</TableCell>
                  <TableCell>
                    <Link target="_blank" to={summaryData.pr_url}>
                      {summaryData.pr_url}
                    </Link>
                  </TableCell>
                </TableRow>
              )}
              {/* add all of these values "num_files_before": 6,
    "num_lines_before": 349,
    "num_files_after": 7,
    "num_lines_after": 424 */}
              <TableRow>
                <TableCell>Number of Files Before Conversion</TableCell>
                <TableCell>{summaryData.num_files_before ?? notAvailable}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Number of Lines Before Conversion</TableCell>
                <TableCell>{summaryData.num_lines_before ?? notAvailable}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Number of Files After Conversion</TableCell>
                <TableCell>{summaryData.num_files_after ?? notAvailable}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Number of Lines After Conversion</TableCell>
                <TableCell>{summaryData.num_lines_after ?? notAvailable}</TableCell>
              </TableRow>
            </tbody>
          </Table>
        </Card>
      </InfoContainer>
      {/* <Title>Test Statistics</Title>
      <InfoContainer>
        <Card bordered={false} style={{ width: '90%' }}>
          <Table>
            <thead>
              <TableRow>
                <TableHeader>Metrics</TableHeader>
                <TableHeader>Built-In Testcases</TableHeader>
                <TableHeader>After Generating</TableHeader>
                <TableHeader>After Conversion</TableHeader>
              </TableRow>
            </thead>
            <tbody>
              <TableRow>
                <TableCell>Passed Count</TableCell>
                <TableCell>
                  {summaryData.built_in_tests.passed ?? notAvailable}
                </TableCell>
                <TableCell>
                  {summaryData.generated_tests.passed ?? notAvailable}
                </TableCell>
                <TableCell>
                  {summaryData.converted_tests.passed ?? notAvailable}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>% Passed</TableCell>
                <TableCell>
                  {summaryData.built_in_tests.passed_percent
                    ? summaryData.built_in_tests.passed_percent + '%'
                    : notAvailable}
                </TableCell>
                <TableCell>
                  {summaryData.generated_tests.passed_percent
                    ? summaryData.generated_tests.passed_percent + '%'
                    : notAvailable}
                </TableCell>
                <TableCell>
                  {summaryData.converted_tests.passed_percent
                    ? summaryData.converted_tests.passed_percent + '%'
                    : notAvailable}
                </TableCell>
              </TableRow>
            </tbody>
          </Table>
        </Card>
      </InfoContainer> */}
      {/* <Title>Conversion Statistics</Title>
      <InfoContainer>
        <Card bordered={false} style={{ width: '90%' }}>
          <Table>
            <thead>
              <TableRow>
                <TableHeader>Metrics</TableHeader>
                <TableHeader>Before Conversion</TableHeader>
                <TableHeader>After Conversion</TableHeader>
              </TableRow>
            </thead>
            <tbody>
              <TableRow>
                <TableCell>Lines of Code</TableCell>
                <TableCell>{summaryData.line.lines_before ?? notAvailable}</TableCell>
                <TableCell>{summaryData.line.lines_after ?? notAvailable}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>No. of Files</TableCell>
                <TableCell>{summaryData.file.files_before ?? notAvailable}</TableCell>
                <TableCell>{summaryData.file.files_after ?? notAvailable}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>No. of Functions</TableCell>
                <TableCell>{summaryData.function.functions_before ?? notAvailable}</TableCell>
                <TableCell>{summaryData.function.functions_after ?? notAvailable}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>No. of Classes</TableCell>
                <TableCell>{summaryData.class.class_before ?? notAvailable}</TableCell>
                <TableCell>{summaryData.class.class_after ?? notAvailable}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Test Passed Count</TableCell>
                <TableCell>{summaryData.built_in_tests.passed ?? notAvailable}</TableCell>
                <TableCell>{summaryData.generated_tests.passed ?? notAvailable}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>% Tests Passed</TableCell>
                <TableCell>
                  {summaryData.built_in_tests.passed_percent
                    ? summaryData.built_in_tests.passed_percent + '%'
                    : notAvailable}
                </TableCell>
                <TableCell>
                  {summaryData.generated_tests.passed_percent
                    ? summaryData.generated_tests.passed_percent + '%'
                    : notAvailable}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Test Code Coverage</TableCell>
                <TableCell>
                  {summaryData.code_coverage.coverage_before
                    ? summaryData.code_coverage.coverage_before + '%'
                    : notAvailable}
                </TableCell>
                <TableCell>
                  {summaryData.code_coverage.coverage_after
                    ? summaryData.code_coverage.coverage_after + '%'
                    : notAvailable}
                </TableCell>
              </TableRow>

            </tbody>
          </Table>
        </Card>
      </InfoContainer> */}
      {/* <TableRow>
                <TableCell>Code Complexity (Radon)</TableCell>
                <TableCell>
                  {summaryData.code_complexity.complexity_before ??
                    notAvailable}
                </TableCell>
                <TableCell>
                  {summaryData.code_complexity.complexity_after ?? notAvailable}
                </TableCell>
              </TableRow> */}
    </div>
  );
};

export default InfoSummary;
