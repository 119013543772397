import { Button, Layout as AntdLayout, Typography, Modal, Input, message } from 'antd';
import React, { useState } from 'react';
import styled from '@emotion/styled';
import withAuthentication from '../../HOC/withAuthentication';
import Layout from '../../layout';
import { useGetUserQuery, useUpdateUserPasswordMutation } from '../../redux/api/user';
import { Controller, useForm } from 'react-hook-form';

type FormData = {
  password: string;
  new_password: string;
  confirm_password: string;
};

const Error = styled.div(() => ({ marginBottom: 10, color: 'red' }));

const FieldContainer = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 6,
}));

const Container = styled.div(() => ({
  padding: 20,
}));

const FieldName = styled.div(() => ({
  color: '#000000',
  fontSize: 16,
  fontWeight: 600,
}));

const TitleName = styled.div(() => ({
  fontSize: 14,
}));

const Profile = () => {
  const { Title } = Typography;
  const { Header } = AntdLayout;
  const { data: getUser } = useGetUserQuery();
  const [updatePassword] = useUpdateUserPasswordMutation();
  const [loading] = useState(false);
  const [error, setError] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<FormData>({
    defaultValues: {
      password: '',
      new_password: '',
      confirm_password: '',
    },
  });

  const onSubmit = async (data: FormData) => {
    if (getUser !== undefined) {
      await updatePassword({ id: getUser.id, ...data })
        .unwrap()
        .then(() => {
          setError('');
          message.success('Password Changed Successfully');
          setIsModalOpen(false);
          setValue('password', '');
          setValue('new_password', '');
          setValue('confirm_password', '');
        })
        .catch((error: any) => {
          setError(error.data.detail);
        });
    }
  };

  const onModalClose = () => {
    setIsModalOpen(false);
    setValue('password', '');
    setValue('new_password', '');
    setValue('confirm_password', '');
  };
  return (
    <Layout>
      <Header
        style={{
          padding: 0,
          background: '#ffffff',
          alignItems: 'center',
          display: 'flex',
          paddingLeft: 20,
          justifyContent: 'space-between',
          paddingRight: 20,
        }}
      >
        <Title level={2} style={{ margin: 0 }}>
          Profile
        </Title>
      </Header>

      <Container>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 22 }}>
          <FieldContainer>
            <FieldName>User Name : </FieldName>
            <div>{getUser?.name}</div>
          </FieldContainer>
          <FieldContainer>
            <FieldName>Email : </FieldName>
            <div>{getUser?.email}</div>
          </FieldContainer>

          <Button type="primary" onClick={() => setIsModalOpen(true)} style={{ width: 150 }} loading={loading}>
            Change Password
          </Button>
          <Modal title="Change Password" open={isModalOpen} onCancel={onModalClose} footer={null}>
            <Container>
              <form name="basic" style={{ maxWidth: 400 }}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 22 }}>
                  {error && <div style={{ color: 'red' }}> {error}</div>}
                  <FieldContainer>
                    <TitleName>Current Password : </TitleName>
                    <Controller
                      name="password"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <>
                          <Input.Password {...field} />
                          {errors.password && <Error>Current Password is required.</Error>}
                        </>
                      )}
                    />
                  </FieldContainer>
                  <FieldContainer>
                    <TitleName>New Password : </TitleName>
                    <Controller
                      name="new_password"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <>
                          <Input.Password {...field} />
                          {errors.new_password && <Error>New Password is required.</Error>}
                        </>
                      )}
                    />
                  </FieldContainer>
                  <FieldContainer>
                    <TitleName>Confirm Password : </TitleName>
                    <Controller
                      name="confirm_password"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <>
                          <Input.Password {...field} />
                          {errors.confirm_password && <Error>Confirm Password is required.</Error>}
                        </>
                      )}
                    />
                  </FieldContainer>

                  <Button type="primary" onClick={handleSubmit(onSubmit)} style={{ width: 150 }} loading={loading}>
                    Change Password
                  </Button>
                </div>
              </form>
            </Container>
          </Modal>
        </div>
      </Container>
    </Layout>
  );
};

export default withAuthentication(Profile);
