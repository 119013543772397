import React from 'react';
import { Result } from 'antd';
import Layout from '../../layout';
import Header from '../Header';
import Footer from '../Footer';

const NotFound = () => {
  return (
    <div style={{ flexGrow: 1 }}>
      <Header />
      <Result status="404" title="404" subTitle="Sorry, the page you visited does not exist." />
      <Footer />
    </div>
  );
};

export default NotFound;
