import styled from '@emotion/styled';
import { Typography } from 'antd';
import React from 'react';
import withAuthentication from '../../HOC/withAuthentication';
import Layout from '../../layout';

const { Title } = Typography;

const Container = styled.div(() => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  textAlign: 'center',
}));

function Welcome() {
  return (
    <Layout>
      <Container>
        <Title level={2}>Welcome to Coderbotics</Title>
      </Container>
    </Layout>
  );
}

export default withAuthentication(Welcome);
