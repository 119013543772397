import React, { FC } from 'react';
import { ReactComponent as LoadingIconSVG } from '../../assets/loading.svg';
import { IconProps } from './types';
import styled from '@emotion/styled';

// make the svg spin and add @keyframes spin

const SpinningSVG = styled(LoadingIconSVG)`
  animation: spin 3s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-359deg);
    }
  }
`;

const LoadingIcon: FC<IconProps> = ({ size, color }) => {
  return <SpinningSVG width={size} height={size} fill={color} />;
};

export default LoadingIcon;
